import styled from 'styled-components';
import './App.css';
import SiteCard from './components/SiteCard';
import SectionHeader from './components/SectionHeader';
import sites from './data/sites';

const generateSiteCards = (sites) => {
  const output = [];

  sites.forEach((section) => {
    const sectionName = section.name;
    output.push(<SectionHeader key={sectionName}>{sectionName}</SectionHeader>);
    section.sites.forEach((site) => {
      output.push(
        <SiteCard
          key={site.title}
          title={site.title}
          link={site.link}
          description={site.description}
          image={site.image}
        />
      );
    });
  });

  return output;
};

function App() {
  const sitesData = generateSiteCards(sites);
  console.log(sitesData);
  return (
    <PageContainer className="App">
      <Header>
        <h1>Games and Game Tools by Lloyd</h1>
      </Header>
      <GamesArea>{sitesData}</GamesArea>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  color: white;
  padding: 0 auto;

  h1,
  h2 {
    text-align: center;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 1);
  }
  h1 {
    font-size: 2.5rem;
  }
`;

const Header = styled.header`
  background: rgb(13, 94, 150);
  background: radial-gradient(
    circle,
    rgba(130, 30, 13, 1) 0%,
    rgba(52, 4, 2, 1) 100%
  );
  max-height: 15vh;
  width: 100%;
  padding: 6px 0;
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
`;

const GamesArea = styled.div`
  padding: 0 5%;
  width: 100%;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 20px;
`;

export default App;
