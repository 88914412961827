import img1DChess from '../assets/images/1d-chess.png';
import imgChess from '../assets/images/chess.png';
import imgDnd from '../assets/images/dnd-helper.png';
import imgDruidTable from '../assets/images/druid-wildshape-tool.png';
import imgHanabi from '../assets/images/hanabi-tracker.png';
import imgLiarsDiceOdds from '../assets/images/liars-dice-odds.png';
import imgPetals from '../assets/images/petals.png';
import imgQwixx from '../assets/images/qwixx-score-card.png';
import imgTfMarsHome from '../assets/images/tf-mars-home.png';
import imgXwingRebelGame from '../assets/images/x-wing-rebels-img.png';

const sites = [
  {
    name: 'Tools & Automation',
    sites: [
      {
        title: 'Hanabi Hand Tracker',
        link: 'http://hanabi.lloyd.games',
        description: 'Simple tracker for the card game Hanabi',
        image: imgHanabi,
      },
      {
        title: 'D&D Druid Wild Shape Table',
        link: 'http://druid.lloyd.games',
        description: 'D&D 5e (2014) Druid Wild Shape table',
        image: imgDruidTable,
      },
      {
        title: 'Qwixx Score Sheet',
        link: 'http://qwixx.lloyd.games',
        description: 'Digital score sheet for the dice game Qwixx',
        image: imgQwixx,
      },
      {
        title: 'Terraforming Mars Resource Tracker',
        link: 'http://tf-mars.lloyd.games',
        description: 'Resource tracking for the board game Terraforming Mars',
        image: imgTfMarsHome,
      },
      {
        title: 'X-Wing Miniatures Cards and Game State',
        link: 'http://x-wing.lloyd.games',
        description: 'Manage your Star Wars X-Wing cards and game board',
        image: imgXwingRebelGame,
      },
      {
        title: 'D&D Shop Helper',
        link: 'http://dnd.lloyd.games',
        description: 'Make a D&D 5e shop and manage your inventory',
        image: imgDnd,
      },
      {
        title: "Liar's Dice Odds",
        link: 'https://mahburg.github.io/liars-dice-odds/',
        description: "Graph of odds for Liar's Dice and dice count tracker",
        image: imgLiarsDiceOdds,
      },
    ],
  },
  {
    name: 'Games',
    sites: [
      {
        title: '1D Chess',
        link: 'http://1d-chess.lloyd.games',
        description: 'Simple chess game with a twist (in progress)',
        image: img1DChess,
      },
      {
        title: 'Chess',
        link: 'http://chess.lloyd.games',
        description: 'Weekend chess board project (not finished)',
        image: imgChess,
      },
    ],
  },
  {
    name: 'Puzzles etc...',
    sites: [
      {
        title: 'Petals Around the Rose',
        link: 'https://mahburg.github.io/petals-around-the-rose/',
        description: 'An attempt at bringing a logic/pattern puzzle to the web',
        image: imgPetals,
      },
    ],
  },
];

export default sites;
